const cardContent = [
  {
    id: 1,
    enter_text: "Shots coming from distance...",
    leave_text: "Viper: ''we are picking up gunfire..",
    speech_bubble: "Finally about time you showed up..",
    flx: 500,
  },
  {
    id: 2,
    enter_text: "...did you locate the spike?''",
    leave_text: "",
    speech_bubble: "...and that would be a yes!",
    flx: 300,
  },
  {
    id: 3,
    enter_text: "SWOOSH-whirrrr...",
    leave_text: "",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 4,
    enter_text: "WHOOosSHh..   ",
    leave_text: "",
    speech_bubble: "",
    flx: 275,
  },
  {
    id: 5,
    enter_text: "shhh-THUD.",
    leave_text: "viper: ''stay close, weapons ready''",
    speech_bubble: "",
    flx: 600,
  },
  {
    id: 6,
    enter_text: "",
    leave_text: "",
    speech_bubble: "I was born ready..",
    flx: 500,
  },
  {
    id: 7,
    enter_text: "this way...",
    leave_text: "",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 8,
    enter_text: "Killjoy:''Hey... take a look''",
    leave_text: "",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 9,
    enter_text: "viper:''It's stealing radianite from environment...",
    leave_text: "...how do we stop it..''",
    speech_bubble: "",
    flx: 700,
  },
  {
    id: 10,
    enter_text: "",
    leave_text: "viper:''you'll need a radi-core to power...",
    speech_bubble: "I'm on it",
    flx: 325,
  },
  {
    id: 11,
    enter_text: "alarm-bot destroyed.",
    leave_text: "Killjoy (GASP!):''huuhh!!''",
    speech_bubble: "",
    flx: 350,
  },
  {
    id: 12,
    enter_text: "",
    leave_text: "viper: ''Phoenix waitt..''",
    speech_bubble: "I got this!..",
    flx: 250,
  },
  {
    id: 13,
    enter_text: "RATATATATTT....",
    leave_text: "",
    speech_bubble: "",
    flx: 475,
  },
  {
    id: 14,
    enter_text: "UHH... HUHH...",
    leave_text: "",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 15,
    enter_text: "",
    leave_text: "SIZZLE-BURST!  ",
    speech_bubble: "",
    flx: 475,
  },
  {
    id: 16,
    enter_text: "SWISHH..WHOOSSSHHHH!",
    leave_text: "",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 17,
    enter_text: "",
    leave_text: "KAAA...  ",
    speech_bubble: "",
    flx: 250,
  },
  {
    id: 18,
    enter_text: "...BOOOOOM!",
    leave_text: "",
    speech_bubble: "",
    flx: 300,
  },
  {
    id: 19,
    enter_text: "THUD",
    leave_text: "PHOENIX:''HANDS-UP...",
    speech_bubble: "",
    flx: 550,
  },
  {
    id: 20,
    enter_text: "...COME ON''  ",
    leave_text: "STANDS UP..",
    speech_bubble: "",
    flx: 300,
  },
  {
    id: 21,
    enter_text: "..SMIRKS",
    leave_text: "",
    speech_bubble: "",
    flx: 525,
  },
  {
    id: 22,
    enter_text: "SCREEE.....",
    leave_text: "",
    speech_bubble: "",
    flx: 275,
  },
  {
    id: 23,
    enter_text: "....EEEEEE....",
    leave_text: "....EEEEE....",
    speech_bubble: "",
    flx: 275,
  },
  {
    id: 24,
    enter_text: "....EECH!",
    leave_text: "",
    speech_bubble: "",
    flx: 500,
  },
  {
    id: 25,
    enter_text: "",
    leave_text: "",
    speech_bubble: "PHOENIX(GASPS): HUHH..?",
    flx: 300,
  },
  {
    id: 26,
    enter_text: "LAUGHING..",
    leave_text: "",
    speech_bubble: "YOU SHOULD SEE YOUR FACE..",
    flx: 425,
  },
  {
    id: 27,
    enter_text: "SIZZLE.. ",
    leave_text: "",
    speech_bubble: "",
    flx: 375,
  },
  {
    id: 28,
    enter_text: "VIPER PUSHES PHOENIX ASIDE..",
    leave_text: "",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 29,
    enter_text: "BANG.. KA-CHUNK!  ",
    leave_text: "",
    speech_bubble: "",
    flx: 600,
  },
  {
    id: 30,
    enter_text: "BANG.. KA-CHUNK!  ",
    leave_text: "",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 31,
    enter_text: "BANG.. KA-CHUNK!  ",
    leave_text: "ESCAPES.. ",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 32,
    enter_text: "",
    leave_text: "",
    speech_bubble: "WHAT THE F-",
    flx: 600,
  },
  {
    id: 33,
    enter_text: "VIPER: ''COME ON''",
    leave_text: "",
    speech_bubble: "",
    flx: 300,
  },
  {
    id: 34,
    enter_text: "",
    leave_text: "BEEP... BEEP... BEEP...",
    speech_bubble: "",
    flx: 500,
  },
  {
    id: 35,
    enter_text: "... BEEP... BEEP... BEEP...",
    leave_text: "",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 36,
    enter_text: "VIPER: ''HERE''",
    leave_text: "",
    speech_bubble: "",
    flx: 300,
  },
  {
    id: 37,
    enter_text: "",
    leave_text: "KILLJOY: ''HERE...",
    speech_bubble: "DID YOU FIND A POWER SOURCE",
    flx: 500,
  },
  {
    id: 38,
    enter_text: "",
    leave_text: "",
    speech_bubble: "VERDAMMT! NOT ENOUGH POWER",
    flx: 300,
  },
  {
    id: 39,
    enter_text: "",
    leave_text: "",
    speech_bubble: "IT NEEDS TO BE SUPER HEATED",
    flx: 325,
  },
  {
    id: 40,
    enter_text: "",
    leave_text: "",
    speech_bubble: "GIVE THAT HERE",
    flx: 300,
  },
  {
    id: 41,
    enter_text: "SWOOSSHHH...!",
    leave_text: "",
    speech_bubble: "",
    flx: 625,
  },
  {
    id: 42,
    enter_text: "TOSS..",
    leave_text: "SNAP... CLICK... ",
    speech_bubble: "",
    flx: 275,
  },
  {
    id: 43,
    enter_text: "BEEP...",
    leave_text: "",
    speech_bubble: "",
    flx: 300,
  },
  {
    id: 44,
    enter_text: "THRRARARRR...  ",
    leave_text: "",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 45,
    enter_text: "",
    leave_text: "...THRRARARRR  ",
    speech_bubble: "",
    flx: 500,
  },
  {
    id: 46,
    enter_text: "WOOSH",
    leave_text: "THUD",
    speech_bubble: "",
    flx: 275,
  },
  {
    id: 47,
    enter_text: "",
    leave_text: "KILLJOY: ''I HONESTLY DIDN'T EXPECT...  ",
    speech_bubble: "",
    flx: 500,
  },
  {
    id: 48,
    enter_text: "...THAT TO WORK''  ",
    leave_text: "PHOENIX: ''REALLY, SO, UH, MAYBE GIVE US A HEADS UP NEXT TIME?''",
    speech_bubble: "",
    flx: 500,
  },
  {
    id: 49,
    enter_text: "",
    leave_text: "",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 50,
    enter_text: "",
    leave_text: "PHOENIX: ''THAT THE BIG MAN?...",
    speech_bubble: "SITE SECURED, WE'RE COMING HOME.",
    flx: 600,
  },
  {
    id: 51,
    enter_text: "...TELL HIM I NEED ANSWERS THIS TIME!''",
    leave_text: "KILLJOY: ''WHAT IS YOU PROBLEM?...",
    speech_bubble: "",
    flx: 400,
  },
  {
    id: 52,
    enter_text: "...WE CRUSHED IT!''",
    leave_text: "VIPER: ''PHOENIX GOT A LOOK IN THE MIRROR''",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 53,
    enter_text: "PHOENIX: ''HOW COME THEY LOOK LIKE US?...",
    leave_text: "AND WHAT DO THEY WANT OUR RADIANITE FOR?''",
    speech_bubble: "",
    flx: 325,
  },
  {
    id: 54,
    enter_text: "VIPER: ''I'LL TALK TO BRIMSTONE,...",
    leave_text: "... IT'S TIME WE FILLED YOU IN.''",
    speech_bubble: "",
    flx: 500,
  },
  {
    id: 55,
    enter_text: "WHUUMPPP!",
    leave_text: "",
    speech_bubble: "",
    flx: 800,
  },
];

export default cardContent;
